import './App.css';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Phone from './components/phone/Phone';
import { GiHamburgerMenu } from "react-icons/gi";
import Acasa from './components/Acasa/Acasa';
import Footer from './components/Footer/Footer';
import Contact from './components/Contact/Contact';
import Servicii from './components/Servicii/Servicii';
import { useTranslation } from 'react-i18next';
import './i18n';
import Despre from './components/Despre/Despre';
import logo from '../src/assets/logo.png';

function App() {
  const [currentPage,setCurrentPage]=useState('acasa')
  const [mobileMenu,setMobileMenu]=useState(false)
  const { t, i18n } = useTranslation();
  

  useEffect(()=>{
    setMobileMenu(false)
  },[currentPage])

  const footerChangePage=(page)=>{
    setCurrentPage(page);
    window.scrollTo(0, 0)
  } 
  useEffect(() => {
      window.scrollTo(0, 0)
    }, [currentPage])
  useEffect(() => {
      changeLanguage('ro')
    }, [])

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
  
  return (
    <div className="App">
       <Phone/>
       <nav className='nav padding'>
          <div className='logo' onClick={()=>{setCurrentPage('acasa')}}>
            <img className='logo-main' src={logo} alt="" />
          </div>
          <ul className='menu-list'>
            <li onClick={() => { setCurrentPage('acasa'); }} className={currentPage === 'acasa' ? 'current-page' : ''}>{t('acasa')}</li>
            <li onClick={() => { setCurrentPage('despre'); }} className={currentPage === 'despre' ? 'current-page' : ''}>{t('despre')}</li>
            <li onClick={() => { setCurrentPage('servicii'); }} className={currentPage === 'servicii' ? 'current-page' : ''}>{t('servicii')}</li>
            <li onClick={() => { setCurrentPage('contact'); }} className={currentPage === 'contact' ? 'current-page' : ''}>{t('contact')}</li>
          </ul>
          <div className='buttons-div'>
             <button className='contact-btn' onClick={()=>{setCurrentPage('contact')}}>{t('contact_us')}</button>
              <div className='lang-div'>
                <button onClick={() => changeLanguage('ro')} className='ro-btn'>Ro</button>
                <button onClick={() => changeLanguage('en')} className='en-btn'>En</button>
              </div>
          </div>
         
          
          <GiHamburgerMenu  className={`menu-icon`} onClick={()=>{setMobileMenu(!mobileMenu)}}/>
          <div className={`mobile-menu ${mobileMenu ? 'mobile-menu-open':'mobile-menu-close'}`}>
            <ul className='menu-list-mobile'>
              <li onClick={() => { setCurrentPage('acasa'); }} className={currentPage === 'acasa' ? 'current-page' : ''}>{t('acasa')}</li>
              <li onClick={() => { setCurrentPage('despre'); }} className={currentPage === 'despre' ? 'current-page' : ''}>{t('despre')}</li>
              <li onClick={() => { setCurrentPage('servicii'); }} className={currentPage === 'servicii' ? 'current-page' : ''}>{t('servicii')}</li>
              <li onClick={() => { setCurrentPage('contact'); }} className={currentPage === 'contact' ? 'current-page' : ''}>{t('contact')}</li>
            </ul>
            <div className='lang-div-mobile'>
              <button onClick={() => changeLanguage('ro')} className='ro-btn'>Ro</button>
              <button onClick={() => changeLanguage('en')} className='en-btn'>En</button>
          </div>
          </div>
       </nav>
       {currentPage==='acasa'?<Acasa footerChangePage={footerChangePage}/>:''}
       {currentPage==='contact'?<Contact/>:''}
       {currentPage==='servicii'?<Servicii footerChangePage={footerChangePage}/>:''}
       {currentPage==='despre'?<Despre footerChangePage={footerChangePage}/>:''}
       
       <Footer footerChangePage={footerChangePage}/>
    


    </div>
  );
}

export default App;
